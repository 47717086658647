#dialog {position:fixed; width:525px; padding:0px; z-index:200; background:#fff; font-family:Verdana, Arial, Helvetica; font-size:14px; }
#dialog-header {display:block; position:relative; padding:3px 6px 7px; height:14px; font-size:14px; font-weight:bold}
#dialog-title {float:left}
#dialog-close {float:right; cursor:pointer; margin:3px 3px 0 0; height:11px; width:11px; background:url(dialog/close.gif) no-repeat}
#dialog-content {display:block; padding:6px; min-height:160px; color:#333; font-size:13px;}
#dialog-content-inner { min-height:128px; }
#dialog-mask {position:fixed; top:0; left:0; height:100%; width:100%; background:#FFF; opacity:.75; filter:alpha(opacity=75); z-index:100}
#dialog-button-container { text-align:center; margin:2px 6px 6px 2px; }
#dialog-button, #dialog-button2 { font-weight:bold; font-size:1.1em; font-family:Arial, sans-serif; padding:2px 5px 2px 5px; }
.error {background:#fff url(dialog/e_bg.jpg) bottom right no-repeat; border:1px solid #999; border-top:none}
.errorheader {background:url(dialog/e_hd.gif) repeat-x; color:#333; border:1px solid #999; border-bottom:none}
.errorbutton { background-color:#eee; border:1px solid #924949; color:#333; }
.warning {background:#fff url(dialog/w_bg.jpg) bottom right no-repeat; border:1px solid #999; border-top:none}
.warningheader {background:url(dialog/w_hd.gif) repeat-x; color:#333; border:1px solid #999; border-bottom:none}
.warningbutton { background-color:#eee; border:1px solid #957C17; color:#333; }
.success {background:#fff url(dialog/s_bg.jpg) bottom right no-repeat; border:1px solid #999; border-top:none}
.successheader {background:url(dialog/p_hd.gif) repeat-x; color:#F8F8F8; border:1px solid #999; border-bottom:none}
.successbutton { background-color:#eee; border:1px solid #fff; color:#333; }
.prompt {background:#fff url(dialog/p_bg.jpg) bottom right no-repeat; border:1px solid #999; border-top:none}
.promptheader {background:url(dialog/p_hd.gif) repeat-x; color:#355468; border:1px solid #999; border-bottom:none}
.promptbutton { background-color:#eee; border:1px solid #fff; color:#333; }
