@font-face {
  font-family: 'fontello';
  src: url('/public/skorki/common/fonts//fontello/fontello.eot?66376778');
  src: url('/public/skorki/common/fonts//fontello/fontello.eot?66376778#iefix') format('embedded-opentype'),
       url('/public/skorki/common/fonts//fontello/fontello.woff2?66376778') format('woff2'),
       url('/public/skorki/common/fonts//fontello/fontello.woff?66376778') format('woff'),
       url('/public/skorki/common/fonts//fontello/fontello.ttf?66376778') format('truetype'),
       url('/public/skorki/common/fonts//fontello/fontello.svg?66376778#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?66376778#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-check:before { content: '\e800'; } /* '' */
.icon-cancel:before { content: '\e801'; } /* '' */
.icon-heart:before { content: '\e802'; } /* '' */
.icon-login:before { content: '\e803'; } /* '' */
.icon-logout:before { content: '\e804'; } /* '' */
.icon-th-large:before { content: '\e805'; } /* '' */
.icon-right-open-outline:before { content: '\e806'; } /* '' */
.icon-user:before { content: '\e807'; } /* '' */
.icon-info:before { content: '\e808'; } /* '' */
.icon-menu:before { content: '\e809'; } /* '' */
.icon-th:before { content: '\e80a'; } /* '' */
.icon-bag:before { content: '\e80b'; } /* '' */
.icon-resize-full:before { content: '\e80c'; } /* '' */
.icon-left-open:before { content: '\e80d'; } /* '' */
.icon-right-open:before { content: '\e80e'; } /* '' */
.icon-to-start:before { content: '\e80f'; } /* '' */
.icon-to-end:before { content: '\e810'; } /* '' */
.icon-dot-3:before { content: '\e811'; } /* '' */
.icon-home:before { content: '\e812'; } /* '' */
.icon-search:before { content: '\e813'; } /* '' */
.icon-cog:before { content: '\e814'; } /* '' */
.icon-trash:before { content: '\e815'; } /* '' */
.icon-arrows-ccw:before { content: '\e816'; } /* '' */
.icon-up-open:before { content: '\e817'; } /* '' */
.icon-th-list:before { content: '\e818'; } /* '' */
.icon-beaker:before { content: '\e819'; } /* '' */
.icon-heart-empty:before { content: '\e81a'; } /* '' */
.icon-down-open:before { content: '\e81b'; } /* '' */
.icon-right-dir:before { content: '\e81c'; } /* '' */
.icon-left-dir:before { content: '\e81d'; } /* '' */
.icon-location:before { content: '\e81e'; } /* '' */
.icon-picture:before { content: '\e81f'; } /* '' */
.icon-search-1:before { content: '\e820'; } /* '' */
.icon-mail:before { content: '\e821'; } /* '' */
.icon-star:before { content: '\e822'; } /* '' */
.icon-star-empty:before { content: '\e823'; } /* '' */
.icon-ok:before { content: '\e824'; } /* '' */
.icon-phone:before { content: '\e825'; } /* '' */
.icon-left-open-1:before { content: '\e826'; } /* '' */
.icon-right-open-1:before { content: '\e827'; } /* '' */
.icon-megaphone:before { content: '\e828'; } /* '' */
.icon-lamp:before { content: '\e829'; } /* '' */
.icon-alert:before { content: '\e82a'; } /* '' */
.icon-lifebuoy:before { content: '\e82b'; } /* '' */
.icon-chart-line:before { content: '\e82c'; } /* '' */
.icon-bell:before { content: '\e82d'; } /* '' */
.icon-plus:before { content: '\e82e'; } /* '' */
.icon-minus:before { content: '\e82f'; } /* '' */
.icon-info-circled:before { content: '\e830'; } /* '' */
.icon-help-circled:before { content: '\e831'; } /* '' */
.icon-calendar-outlilne:before { content: '\e832'; } /* '' */
.icon-basket-1:before { content: '\e833'; } /* '' */
.icon-down-dir:before { content: '\e834'; } /* '' */
.icon-shop:before { content: '\e835'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-shopping-bag:before { content: '\f290'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
.icon-facebook:before { content: '\f30c'; } /* '' */
.icon-pinterest:before { content: '\f312'; } /* '' */
.icon-linkedin:before { content: '\f318'; } /* '' */
.icon-instagram:before { content: '\f31e'; } /* '' */