@include media-breakpoint-up(xxl){
  body{
    /** Owl Carousel FIX dla responsive flexbox */
    .owl-nav{
      width: calc(100% + 3rem);
      left: -1.5rem;
      .owl-prev, .owl-next{
        width: 1.5rem;
      }
    }
    .owl-carousel-producenci{
      .owl-nav{
        width: calc(100% + 4rem);
        left: -2rem;
        .owl-prev, .owl-next{
          width: 2rem;
        }
      }
    }
  }
}