@include media-breakpoint-up(xl){
  body{
    .navbar-brand{
      font-size: 1rem;
    }
    #lista-produktow{
      
    }
    /** Owl Carousel FIX dla responsive flexbox */
    .owl-nav{
      width: calc(100% + 3rem);
      left: -1.5rem;
      .owl-prev, .owl-next{
        width: 1.5rem;
      }
    }
    
    .predash{
      .display-4 {
        font-size: 3.5rem !important; 
       }
    }
  } 
}